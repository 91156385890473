import React from 'react'
import { FaArrowUp } from 'react-icons/fa'

import $ from 'jquery'

export default function ScrollTopButton() {
    return (
        <div className='floating-button-div'>
            <button
                onClick={e => {
                    e.preventDefault();
                    document.getElementById('start').scrollIntoView({ block: 'start', behavior: 'smooth' });
                }}
                id='scrollTOpBtn'
                className='scrollTopButton'>
                <FaArrowUp />
            </button>
        </div>
    )
}
