import React from 'react'
import { Button, Container } from 'react-bootstrap'
import { FaEnvelope, FaInstagram, FaPhone, FaWhatsapp } from 'react-icons/fa'

export default function Footer() {
  return (
    <footer className='footer'>
      <Container>
        <div className='d-flex justify-content-center'>
          <ul className='contact-icons'>
            <li>
              <Button href='tel://+4917635489040' className='bg-transparent'><FaPhone /></Button>
            </li>
            <li>
              <Button href='mailto://info@techterra-labs.com' className='bg-transparent'>
                <FaEnvelope />
              </Button>
            </li>
            <li>
              <Button href='https://wa.me/message/F7N3YAVV62GGC1' className='bg-transparent'>
                <FaWhatsapp />
              </Button>
            </li>
            <li>
              <Button style={{backgroundColor: 'transparent', border: 'none'}} href='https://instagram.com/techterra.labs'>
                <FaInstagram />
              </Button>
            </li>
          </ul>
        </div>


        <ul className='text-center links'>
          <li><a href='/impressum'>Impressum</a></li>
          <li><a href='/datenschutz'>Datenschutz</a></li>
          {/* <li><a href=''>AGB</a></li> */}
        </ul>

        <p className='text-center pt-2'>&copy; Copyright by TechTerraLabs</p>
      </Container>
    </footer>
  )
}
