import React from 'react'
import { Card, Container } from 'react-bootstrap'

import "slick-carousel/slick/slick.css";

import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";

import galleryData from '../utils/gallery.json'

export default function Gallery() {


  var settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 3000,
    useCSS: true,
    slidesToShow: 3,
    arrows: false,
    className: 'slick-slider',
    responsive: [{
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1
      }
    }]
  };

  return (
    <Container className='pt-5 pb-5'>
      <h2 className='sec-title'>Galerie</h2>

      <div className='slider'>
        <Slider {...settings}>
          {galleryData.map((item, index) => {
            return (
              <div className='p-2' key={'gallery-' + index}>
                <Card>
                  <Card.Img src={item.src} />
                </Card>
              </div>
            )
          })}
        </Slider>
      </div>
    </Container>
  )
}
