import React from 'react'

import { Card } from 'react-bootstrap'

import $ from 'jquery';

export default function ServiceCardFore({ service, hoverCard, leaveCard, cardIndex }) {

  $(document).on('mouseover', '#service-card-' + cardIndex, (event) => {
    event.preventDefault();
    hoverCard(cardIndex);
  });


  $(document).on('mouseout', '#service-card-' + cardIndex, (event) => {
    event.preventDefault();
    leaveCard(cardIndex);
  });

  return (
    <div>
      <Card className='card' id={'service-card-' + cardIndex}>
        <Card.Img src={service.src} />
        <Card.Body className='text-center'>
          {cardIndex == 2 ? (
            <Card.Title className='card-title'>
              <br />
              {service.title}
            </Card.Title>
          ) : (
            <Card.Title className='card-title'>
              {service.title}
            </Card.Title>
          )}
        </Card.Body>
      </Card>
    </div>
  )
}
