import React from 'react'
import { FaBars } from 'react-icons/fa'

import { Navbar, Nav, Container, Image } from 'react-bootstrap'

export default function NavBar({ scrollToId }) {
    return (
        <div className='navbar-before'>
            <Navbar expand="lg"  variant='dark'>
                <Container>
                <Navbar.Brand onClick={E => {
                    E.preventDefault();
                    scrollToId(`start`);
                }} className='navbar-brand'>
                    <Image className='logo' src='/images/darkLogo.png' />
                </Navbar.Brand>
               
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mx-auto">
                        <Nav.Link href='' onClick={e => {
                            e.preventDefault();
                            scrollToId("start");
                        }}>Start</Nav.Link>
                        <Nav.Link onClick={e => {
                            e.preventDefault();
                            scrollToId("aboutus");
                        }}>Über Uns</Nav.Link>
                        <Nav.Link onClick={e => {
                            e.preventDefault();
                            scrollToId("services");
                        }}>Dienstleistungen</Nav.Link>
                        <Nav.Link onClick={e => {
                            e.preventDefault();
                            scrollToId("cform");
                        }}>Kontakt</Nav.Link>
                        <Nav.Link onClick={e => {
                            e.preventDefault();
                            scrollToId("gallery");
                        }}>Galerie</Nav.Link>
                    </Nav>
                </Navbar.Collapse>

                <Navbar.Toggle aria-controls="basic-navbar-nav" />
             
                </Container>
            </Navbar>
        </div>
    )
}