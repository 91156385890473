import React from 'react'

import { Card } from 'react-bootstrap'

export default function ServiceCardBack({ cardIndex, service }) {
  return (
    <Card style={{minHeight: '50vh'}} className='card' id={'service-card-back' + cardIndex}>
      <Card.Body>
        {service.text}
      </Card.Body>
    </Card>
  )
}
