import React, { Component } from 'react'

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import 'bootstrap/dist/js/bootstrap.min.js'

import Home from './pages/Home'
import Impressum from './pages/Impressum'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Datenschutz from './pages/Datenschutz';

export default class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path='' element={<Home />} />
          <Route path='/impressum' element={<Impressum />} />
          <Route path='/datenschutz' element={<Datenschutz />} />
        </Routes>
      </BrowserRouter>
    )
  }
}
