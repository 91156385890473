import React, { Component } from 'react'

import Footer from '../components/Footer'
import { Container } from 'react-bootstrap'

export default class Impressum extends Component {
    render() {
        return (
            <div className='scroll-container'>
                <section id='imprint' className='scroll-section pt-5 pb-5'>
                    <Container>
                        <h1>Impressum</h1>

                        <p>Angaben gemäß § 5 TMG:</p>
                        <p>Melih Can Akbulut<br />
                            Max-Fremery-Str. 12<br />
                            50827 Köln</p>

                        <p>Kontakt:</p>
                        <p>Telefon: +4917635489040<br />
                            E-Mail: info@techterra-labs.com </p>

                        <p>Umsatzsteuer-ID gemäß §27a UStG:<br />
                            -</p>

                        <p>Kleinunternehmer im Sinne von §19 UStG.</p>

                        <p>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</p>
                        <p>Melih Can Akbulut<br />
                            Max-Fremery-Str. 12<br />
                            50827 Köln</p>

                        <p>Haftungsausschluss:</p>

                        <p>Haftung für Inhalte<br />
                            Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen.</p>

                        <p>Haftung für Links<br />
                            Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen.</p>

                        <p>Urheberrecht<br />
                            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.</p>

                        <p>Datenschutz<br />
                            Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name, Anschrift oder E-Mail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets auf freiwilliger Basis.</p>


                    </Container>
                </section>
                
                <Footer />
            </div>
        )
    }
}
