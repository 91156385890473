import React, { Component } from 'react'
import { Button, Row, Col, Container, Form } from 'react-bootstrap'
import { FaArrowDown, FaEnvelope, FaInstagram, FaPhone, FaWhatsapp } from 'react-icons/fa'

import services from '../utils/services.json'
import ReactCardFlip from 'react-card-flip';

import ServiceCardFore from '../components/ServiceCardFore'
import ServiceCardBack from '../components/ServiceCardBack'

import Footer from '../components/Footer'
import ScrollTopButton from '../components/ScrollTopButton';

import Gallery from '../components/Gallery'

import NavBar from '../components/NavBar'

export default class Home extends Component {


  constructor(props) {
    super(props);
    this.state = {
      services: [],
      generatedCode: ''
    };
  }

  componentDidMount() {
    const code = Math.floor(100000 + Math.random() * 900000);
    this.setState({
      services: services,
      generatedCode: code.toString()
    });
  }

  hoverCard(cardIndex) {
    this.state.services[cardIndex].isFlipped = true;
    this.forceUpdate();
  }

  leaveCard(cardIndex) {
    this.state.services[cardIndex].isFlipped = false;
    this.forceUpdate();
  }


  scrollToId(id) {
    document.getElementById(id).scrollIntoView({ block: 'start', behavior: 'smooth' });
  }

  openLink(link) {
    window.location.href = link;
  }



  render() {
    return (
      <>
        <div className='scroll-container'>
          <section id='start' className='scroll-section'>
            <NavBar scrollToId={(id) => this.scrollToId(id)} />
            <div className='mobile-slogan'>
              <h2 className='text-center'>Wir machen ihre Träume digital</h2>
            </div>
          </section>

          {/* ABOUT US */}
          <section className='scroll-section my-bg' id='aboutus'>
            <Container>
              <div className='pt-5 pb-5'>
                <h2 className='sec-title'>
                  Über Uns
                </h2>
                <div className='d-flex justify-content-center p-0 m-0'>
                  <p className='text-center'>
                    Willkommen bei TechTerra Labs
                    <br />
                    Bei TechTerra Labs sind wir der Überzeugung, dass die Verbindung von Technologie, Kreativität und Innovation den Schlüssel zu bahnbrechenden Lösungen bildet. Unser Unternehmen wurde mit dem klaren Ziel gegründet, individuelle Dienstleistungen im Bereich Informationstechnologie, Marketing, Design und Warenhandel anzubieten.
                    <br /><br />
                    <span style={{ fontWeight: 'bold' }}>
                      Unsere Mission:
                    </span>
                    <br />
                    Unsere Mission bei TechTerra Labs besteht darin, unseren Kunden nicht nur Dienstleistungen, sondern umfassende Lösungen zu bieten. Wir verstehen, dass jedes Unternehmen einzigartig ist, und deshalb legen wir Wert darauf, maßgeschneiderte Ansätze zu entwickeln, die genau auf die Bedürfnisse und Ziele unserer Kunden zugeschnitten sind.
                    <br /><br />
                    <span style={{ fontWeight: 'bold' }}>Was uns auszeichnet:</span>

                    <ul className='text-start'>
                      <li>Innovation: Wir sind stets auf der Suche nach neuen Ideen und Technologien, um innovative Lösungen zu schaffen, die Ihre Erwartungen übertreffen</li>
                      <li>Zusammenarbeit: Bei TechTerra Labs sehen wir unsere Kunden nicht nur als Auftraggeber, sondern als Partner. Ihre Vision ist unser Antrieb, und gemeinsam setzen wir Ideen in die Realität um.</li>
                      <li>Kreativität: Unser Team von Experten kombiniert technologisches Know-how mit kreativem Denken. Wir gestalten nicht nur Lösungen, sondern schaffen Erlebnisse.</li>
                      <li>Qualität: Bei allem, was wir tun, streben wir nach höchster Qualität. Von der Softwareentwicklung bis zum Grafikdesign legen wir Wert auf Exzellenz</li>
                    </ul>
                  </p>
                </div>

                <div className='d-flex justify-content-center'>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      this.scrollToId('cform');
                    }}
                    className='goToContactButton'>
                    <FaArrowDown /> Zum Kontaktformular
                  </Button>
                </div>
              </div>
            </Container>
          </section>

          {/* Gallery */}
          <section id='gallery' className='scroll-section my-bg'>
            <Gallery />
          </section>

          {/* SERVICES SECTION */}
          <section className='scroll-section' id='services'>
            <Container>
              <div className='pt-5 pb-5'>
                <h2 className='sec-title'>
                  Dienstleistungen
                </h2>

                <Row className='pt-4 pb-4'>
                  {this.state.services.map((item, index) => {
                    return (
                      <Col md={3} key={index}>
                        <ReactCardFlip isFlipped={item.isFlipped} flipDirection='vertical'>
                          <ServiceCardFore
                            leaveCard={(cardIndex) => this.leaveCard(index)}
                            cardIndex={index}
                            hoverCard={(cardIndex) => this.hoverCard(index)} service={item} />
                          <ServiceCardBack cardIndex={index} service={item} />
                        </ReactCardFlip>
                      </Col>
                    )
                  })}
                </Row>
              </div>
            </Container>
          </section>


          {/* CONTACT FORM */}
          <section className='scroll-section my-bg' id='cform'>
            <Container>
              <div className='pt-5 pb-5'>
                <h2 className='sec-title'>
                  KONTAKT
                </h2>

                <Row className='pt-4 pb-4'>
                  <Col md={6} className='mx-auto'>
                    <Row>
                      <Col md={6} className='col-padding'>
                        <Button href='tel://+4917635489040'
                          className='text-uppercase bg-success text-start w-100'>
                          <FaPhone
                            className='split-icon-from-text' /> Anrufen
                        </Button>
                      </Col>
                      <Col md={6} className='col-padding'>
                        <Button
                          href='mailto://info@techterra-labs.com'
                          className='text-uppercase bg-danger text-start w-100'>
                          <FaEnvelope className='split-icon-from-text' /> E-Mail
                        </Button>
                      </Col>
                      <Col md={6} className='col-padding'>
                        <Button
                          href='https://wa.me/message/F7N3YAVV62GGC1'
                          className='text-uppercase bg-success text-start w-100'>
                          <FaWhatsapp className='split-icon-from-text' /> Whatsapp
                        </Button>
                      </Col>
                      <Col md={6} className='col-padding'>
                        <Button
                          href='https://instagram.com/techterra.labs'
                          className='text-uppercase text-start w-100' style={{ backgroundColor: 'purple' }}>
                          <FaInstagram className='split-icon-from-text' /> Instagram
                        </Button>
                      </Col>
                    </Row>

                    <div className='pt-3 pb-3'>
                      <h6 className='text-center text-uppercase '>ODER</h6>
                    </div>

                    <div className='contactForm'>
                      <Form method='POST' onSubmit={async (e) => {
                        e.preventDefault();
                        var { name, email, message, code } = e.target;
                        if (code.value != this.state.generatedCode) {
                          alert("Der eingegebene Code stimmt nicht überein.");
                        } else {
                          var confirm = window.confirm("Möchten Sie den Kontaktformular wirklich absenden?");
                          if (confirm) {
                            var response = await fetch("https://siteapi.techterra-labs.com/api/v1/form/processContactForm", {
                              method: 'POST',
                              headers: {
                                'Content-Type': 'application/json'
                              },
                              body: JSON.stringify({
                                name: name.value,
                                email: email.value,
                                message: message.value
                              })
                            });
                            var data = await response.json();
                            if (data.failed == false) {
                              alert("Vielen Dank für ihre Nachricht!");
                            }
                          }
                        }
                      }}>
                        <Form.Group>
                          <Form.Label>Ihr Name</Form.Label>
                          <Form.Control type='text' name='name' required placeholder='Ihr Name' />
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>Ihre E-Mail</Form.Label>
                          <Form.Control type='email' name='email' required placeholder='Ihre E-Mail' />
                        </Form.Group>
                        <Form.Group>
                          <Form.Label>Ihre Nachricht</Form.Label>
                          <textarea
                            style={{ minHeight: '120px' }}
                            className='w-100' name='message' required placeholder='Ihre Nachricht' />
                        </Form.Group>

                        <Form.Group>
                          <Form.Label>Geben Sie den Code ein: {this.state.generatedCode}</Form.Label>
                          <Form.Control type='text' name='code' placeholder='Code' required />
                        </Form.Group>

                        <Form.Group>
                          <Button type='submit' className='w-100 bg-success mt-2'>
                            Absenden
                          </Button>
                        </Form.Group>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </section>



          <Footer />
        </div>

        <ScrollTopButton />

      </>
    )
  }
}
