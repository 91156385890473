import React, { Component } from 'react'

import Footer from '../components/Footer'
import { Container } from 'react-bootstrap'

export default class Datenschutz extends Component {
    render() {
        return (
            <div className='scroll-container'>
                <section id='privacypolicy' className='scroll-section pt-5 pb-5'>
                    <Container className='p-2'>
                        <h1>Datenschutzerklärung</h1>

                        <h2>1. Verantwortliche Stelle</h2>
                        <p>Verantwortlich im Sinne der Datenschutzgesetze ist:</p>
                        <address>
                            Melih Can Akbulut]<br />
                            Max-Fremery-Str. 12<br />
                            50827 Köln<br />
                            info@techterra-lbs.com<br />
                            +4917635489040
                        </address>

                        <h2>2. Datenerhebung und -verarbeitung</h2>
                        <p>Wir erheben und verarbeiten personenbezogene Daten, wenn Sie unser Kontaktformular verwenden, anderweitig mit unserer Website interagieren oder unseren Newsletter abonnieren. Die von uns erhobenen Daten umfassen:</p>
                        <ul>
                            <li>Name</li>
                            <li>E-Mail-Adresse</li>
                            <li>Telefonnummer (optional)</li>
                            <li>Nachrichtentext</li>
                        </ul>
                        <p>Zusätzlich verwenden wir Cookies und ähnliche Technologien. Weitere Informationen zu Cookies finden Sie in Abschnitt 4 dieser Datenschutzerklärung.</p>

                        <h2>3. Zweck der Datenverarbeitung</h2>
                        <p>Wir verwenden die von Ihnen bereitgestellten Daten ausschließlich für die Bearbeitung Ihrer Anfrage über das Kontaktformular oder für den Versand unseres Newsletters. Ihre Daten werden nicht für Werbezwecke genutzt, es sei denn, Sie haben ausdrücklich eingewilligt.</p>

                        <h2>4. Nutzung von Cookies</h2>
                        <p>Unsere Website verwendet Cookies, um die Nutzung der Website zu analysieren und die Benutzererfahrung zu verbessern. Cookies sind kleine Textdateien, die auf Ihrem Gerät gespeichert werden. Sie haben die Möglichkeit, Cookies zu akzeptieren oder abzulehnen. Die meisten Webbrowser akzeptieren Cookies automatisch, aber Sie können Ihre Browsereinstellungen anpassen, um Cookies zu blockieren.</p>

                        <h2>5. Nutzung von Google Fonts</h2>
                        <p>Wir nutzen Schriftarten von Google Fonts. Beim Laden von Google Fonts werden Ihre IP-Adresse und technische Informationen an Server von Google übertragen. Weitere Informationen finden Sie in den Datenschutzbestimmungen von Google.</p>

                        <h2>6. Speicherdauer</h2>
                        <p>Ihre Daten werden nur so lange gespeichert, wie es für die Erfüllung des Zwecks, für den sie erhoben wurden, notwendig ist, es sei denn, es bestehen gesetzliche Aufbewahrungspflichten.</p>

                        <h2>7. Weitergabe von Daten an Dritte</h2>
                        <p>Ihre Daten werden nicht ohne Ihre ausdrückliche Zustimmung an Dritte weitergegeben. Eine Ausnahme gilt, wenn wir gesetzlich dazu verpflichtet sind.</p>

                        <h2>8. Sicherheit</h2>
                        <p>Wir setzen technische und organisatorische Sicherheitsmaßnahmen ein, um Ihre Daten gegen unbeabsichtigte oder unrechtmäßige Manipulation, Verlust, Zerstörung oder gegen den Zugriff unberechtigter Personen zu schützen.</p>

                        <h2>9. Ihre Rechte</h2>
                        <p>Sie haben das Recht, Auskunft über die von uns gespeicherten personenbezogenen Daten zu erhalten, deren Berichtigung, Löschung oder Einschränkung der Verarbeitung zu verlangen sowie Widerspruch gegen die Verarbeitung einzulegen. Kontaktieren Sie uns dazu bitte unter den oben genannten Kontaktdaten.</p>

                        {/* <h2>10. Newsletter</h2>
                        <p>Wenn Sie unseren Newsletter abonnieren, verwenden wir die von Ihnen angegebenen Daten ausschließlich für den Versand des Newsletters. Sie können sich jederzeit abmelden, indem Sie den Abmeldelink am Ende jedes Newsletters verwenden.</p> */}

                        <h2>10. Änderungen dieser Datenschutzerklärung</h2>
                        <p>Änderungen unserer Datenschutzerklärung werden auf dieser Seite veröffentlicht. Bitte informieren Sie sich regelmäßig über den aktuellen Stand.</p>

                        <p>Stand: 01.01.2024</p>
                    </Container>
                </section>
                <Footer />
            </div>
        )
    }
}
